import axios from 'axios';
import { closest } from '../common/closest';
export default class FileUploader {
    static init(){
        let uploaders = document.getElementsByClassName('file-uploader');
        if (uploaders.length > 0){
            [...uploaders].forEach(uploader => {
                let dndZone = uploader.querySelector('.dnd-zone');
                dndZone.addEventListener('dragover', function(e){
                    e.preventDefault();
                    if (! dndZone.classList.contains('dragover')){
                        dndZone.classList.add('dragover');
                    }
                });
                dndZone.addEventListener('dragleave', function(e){
                    e.preventDefault();
                    if (dndZone.classList.contains('dragover')) {
                        dndZone.classList.remove('dragover');
                    }
                });
                dndZone.addEventListener('drop', function(e){
                    e.preventDefault();
                    FileUploader.upload(uploader, e.dataTransfer.files);
                });
                let form = uploader.querySelector('.file-upload-form');
                form.addEventListener('submit', function(e){
                    e.preventDefault();
                    FileUploader.upload(uploader, e.originalEvent.dataTransfer.files);
                });
                let filesField = uploader.querySelector('.file-field');
                filesField.addEventListener('change', function(e){
                    FileUploader.upload(uploader);
                });

                FileUploader.getUploadedFiles(uploader);
            });
        }
    }

    static getUploadedFiles(uploader){
        let url = uploader.querySelector('.file-list-api-endpoint').getAttribute('action');
        axios
            .get(url)
            .then(response => {
                if (response.data.result !== 'OK'){
                    toastr.error('アップロードファイル一覧取得に失敗しました');
                    return;
                }
                FileUploader.showUploadedFiles(uploader, response.data.files);
            })
            .catch(error => {
                toastr.error('アップロードファイル一覧取得または一覧表示に失敗しました');
            });
    }

    static upload(uploader, files){
        uploader.querySelector('.dnd-zone').classList.remove('dragover');

        let fileSelectButton = uploader.querySelector('.file-select-button');
        fileSelectButton.setAttribute('disabled', true);

        let form = uploader.querySelector('.file-upload-form');

        let formData;
        if (files){
            formData = new FormData();
            // formData.append('target_uuid', form.querySelector('.target-uuid').value);
            files.forEach(file => {
                formData.append('files[]', file);
            });
        } else {
            formData = new FormData(form);
        }

        let url = form.getAttribute('action');
        axios
            .post(url, formData)
            .then(response => {
                if (response.data.result !== 'OK'){
                    toastr.error('アップロードできませんでした');
                    return;
                }
                toastr.success('ファイルをアップロードしました');
                fileSelectButton.setAttribute('disabled', false);
                FileUploader.showUploadedFiles(uploader, response.data.files);
            })
            .catch(error => {
                toastr.error('アップロードできませんでした');
            });
    }

    static delete(button, deleteEndpoint){
        if (! confirm('削除します。よろしいですか？')){
            return;
        }

        let uuid = button.getAttribute('data-uuid');
        axios
            .post(deleteEndpoint, {
                uuid: uuid,
            })
            .then(response => {
                if (response.data.result !== 'OK'){
                    toastr.error('削除できませんでした');
                    return;
                }
                let li = document.querySelector('li[data-uuid="' + response.data.uuid + '"]');
                if (li){
                    li.parentNode.removeChild(li);
                    toastr.success('削除しました');
                } else {
                    toastr.error('削除に失敗しました');
                }
            })
            .catch(error => {
                toastr.error('削除に失敗しました');
            });
    }

    static showUploadedFiles(uploader, files){
        let fileList = uploader.querySelector('.file-list');
        if (! fileList){
            console.log('Something wrong..');
            return;
        }
        let lis = fileList.querySelectorAll('li');
        [...lis].forEach(li => {
            li.parentNode.removeChild(li);
        });

        files.forEach(file => {
            let li = document.createElement('li');
            li.className = 'd-flex align-items-center mB-5';
            li.setAttribute('data-uuid', file.uuid);

            let deleteButton = document.createElement('div');
            deleteButton.className = 'btn btn-light-danger btn-sm btn-icon delete-button mR-10';
            deleteButton.setAttribute('data-uuid', file.uuid);
            let deleteIcon = document.createElement('i');
            deleteIcon.className = 'fa fa-trash';
            deleteButton.appendChild(deleteIcon);

            deleteButton.addEventListener('click', function(){
                FileUploader.delete(this, file.api_endpoints.delete);
            });

            li.appendChild(deleteButton);

            let containerDiv = document.createElement('div');

            let aDiv = document.createElement('div');
            let a = document.createElement('a');
            a.href = file.api_endpoints.download;
            a.className = 'flex-grow-1 text-break';
            a.textContent = file.filename;
            aDiv.appendChild(a);
            containerDiv.appendChild(aDiv);

            if (file.filesizeKB !== undefined){
                let sizeDiv = document.createElement('div');
                sizeDiv.className = 'small text-muted';
                sizeDiv.textContent = file.filesizeKB + 'KB';

                containerDiv.appendChild(sizeDiv);
            }

            li.appendChild(containerDiv);

            fileList.appendChild(li);
        });
    }
}
