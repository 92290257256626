import axios from "axios";

document.addEventListener('DOMContentLoaded', function() {
    let modals = {};

    let containers = document.querySelectorAll('.conference-text.editable');
    if (containers.length > 0) {
        [...containers].forEach(container => {
            let type = container.getAttribute('data-type');

            let buttons = container.querySelectorAll('.edit-button');
            [...buttons].forEach(button => {
                button.addEventListener('click', function () {
                    modals[type] = new bootstrap.Modal(document.querySelector('.conference-text-modal[data-type="' + type + '"]'), {
                    });
                    modals[type].show();
                });
            });

            document.querySelector('.conference-text-modal[data-type=' + type + ']').addEventListener('submit', event => {
                event.preventDefault();
                let form = event.target;
                let formData = new FormData(form);

                axios
                    .post(form.getAttribute('action'), formData)
                    .then(response => {
                        console.log(response);
                        if (response.data.result === 'OK') {
                            document.querySelector('.conference-text.editable[data-type="' + type + '"] span.text').innerHTML = response.data.data.html;
                            modals[type].hide();
                        } else if (response.data.result === 'ERROR') {
                            alert(response.data.error);
                        }
                    })
                    .catch(error => {
                        toastr.error('テキストの保存に失敗しました');
                    });

                /*
                $.ajax({
                    type: 'POST',
                    url: form.getAttribute('action'),
                    data: formData,
                    processData: false,
                    contentType: false
                }).done(function (response) {
                    if (response.result === 'OK') {
                        document.querySelector('.conference-text.editable[data-type="' + type + '"] span.text').innerHTML = response.data.html;
                        modals[type].hide();
                    } else if (response.result === 'ERROR') {
                        alert(response.data.error);
                    }
                });
                 */
            });
            /*
            $('.conference-text-modal[data-type=' + type + ']').submit(function (event) {
                console.log('submitt');
                event.preventDefault();
                let form = $(event.target);
                let formData = new FormData($(form).get(0));

                $.ajax({
                    type: 'POST',
                    url: form.attr('action'),
                    data: formData,
                    processData: false,
                    contentType: false
                }).done(function (response) {
                    if (response.result === 'OK') {
                        document.querySelector('.conference-text.editable[data-type="' + type + '"] span.text').innerHTML = response.data.html;
                        modals[type].hide();
                    } else if (response.result === 'ERROR') {
                        alert(response.data.error);
                    }
                });
            });
             */
        });
    }
});
