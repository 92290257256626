import axios from 'axios';
import { closest } from '../common/closest';
import TagFilter from 'fortee/js/module/tag-filter';
import TagEntity from 'fortee/js/module/tag-entity';

export default class TagSelector {
    static init(){
        this.tags = {};

        let listTagsEndpoint = document.getElementById('listTagsEndpoint');
        if (! listTagsEndpoint){
            return;
        }

        let url = listTagsEndpoint.getAttribute('action');
        axios
            .get(url, {
                params: {
                }
            })
            .then(response => {
                let data = response.data.data;
                this.tags = data;
                // console.log('tags:');
                // console.log(this.tags);

                const tagSelectorTagLoadedEvent = new CustomEvent('tag-selector-tag-loaded', {
                    detail: {
                        message: 'tag selector tag loaded event',
                    }
                });
                document.dispatchEvent(tagSelectorTagLoadedEvent);
            });

        TagEntity.init();
        TagFilter.init();
    }

    static hideDropDown(element){
        let selectedTags = element.querySelectorAll('.tags-selected .tag');
        [...selectedTags].forEach((tag) => {
            tag.classList.remove('clickable');
            tag.removeEventListener('click', TagSelector.removeTag);
        });
        if (this.popover){
            this.popover.hide();
        }
        let tagsSelected = element.querySelector('.tags-selected');
        if (! tagsSelected){
            console.log('Something wrong..');
        }
        tagsSelected.classList.remove('bg-light-danger');
    }

    static showDropDown(element){
        let type = element.getAttribute('data-type');
        if (! (type in this.tags)){
            return;
        }

        let firstTag = element.querySelector('.tags-selected .tag');
        if (firstTag){
            firstTag.setAttribute('data-bs-toggle', 'tooltip');
            this.popover = new bootstrap.Popover(firstTag, {
                title: 'クリックでタグを削除します',
                trigger: 'manual',
                placement: 'top',
                inverse: 'true',
                offset: '0,15',
            });
            this.popover.show();

            let tagsSelected = element.querySelector('.tags-selected');
            if (! tagsSelected){
                console.log('Something wrong..');
            }
            tagsSelected.classList.add('bg-light-danger');
        }


        let availableTags = element.querySelector('.available-tags');
        TagSelector.clearTags(availableTags);
        this.tags[type].forEach((tag) => {
            let existingTag = element.querySelector('.tags-selected [data-uuid="' + tag.uuid + '"]');
            if (existingTag){
                existingTag.classList.add('clickable');
                existingTag.addEventListener('click', TagSelector.removeTag);
            } else {
                let tagDiv = TagSelector.newTag(tag);
                tagDiv.classList.add('clickable');
                tagDiv.addEventListener('click', TagSelector.addTag);
                availableTags.appendChild(tagDiv);
            }
        });

        if (! availableTags.querySelector('.tag')){
            availableTags.appendChild(TagSelector.noneDiv());
        }
    }

    static addTag(){
        let selector = closest(this, '.tag-selector');
        if (selector){
            TagEntity.added(selector, this);
        }
        let filter = closest(this, '.tag-filter');
        if (filter){
            TagFilter.added(filter, this);
        }
        if ((! selector) && (! filter)){
            console.log('Something wrong.');
        }
    }

    static removeTag(){
        let selector = closest(this, '.tag-selector');
        if (selector){
            TagEntity.removed(selector, this);
        }
        let filter = closest(this, '.tag-filter');
        if (filter){
            TagFilter.removed(filter, this);
        }
        if ((! selector) && (! filter)){
            console.log('Something wrong.');
        }
    }

    static newTag(tag, withMinus = false){
        const tagDiv = document.createElement('div');
        tagDiv.className = 'tag badge rounded-pill mR-5 mB-5';
        tagDiv.textContent = tag.name;
        if (withMinus){
            tagDiv.innerHTML = '<i class="fa fa-solid fa-circle-minus text-white fs-8 mR-5"></i>' + tagDiv.innerHTML;
        }
        if (tagDiv.is_public){
            tagDiv.innerHTML += '<i class="fa fa-lock-open text-white fs-8 mL-5"></i>';
        }
        tagDiv.style.backgroundColor = tag.color_background;
        tagDiv.style.color = tag.color_text;
        tagDiv.setAttribute('data-uuid', tag.uuid);

        return tagDiv;
    }

    static noneDiv(){
        const div = document.createElement('div');
        div.className = 'message text-muted';
        div.textContent = 'なし';
        return div;
    }

    static clearTags(element){
        let existingTags = element.querySelectorAll('.tag');
        [...existingTags].forEach((existingTag) => {
            existingTag.removeEventListener('click', TagSelector.addTag);
            existingTag.parentNode.removeChild(existingTag);
        });
        let messages = element.querySelectorAll('.message');
        [...messages].forEach((element) => {
            element.parentNode.removeChild(element);
        });
    }

    static refreshSelectedTags(element, tags){
        element.innerHTML = '';
        tags.forEach((tag) => {
            let tagDiv = TagSelector.newTag(tag);
            element.appendChild(tagDiv);
        });
    }
}
