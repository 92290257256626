require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/disable-selection');

var Sortable = Sortable || {
    helper: function(e, tr){
        var $originals = tr.children();
        var $helper = tr.clone();
        $helper.children().each(function(index) {
            $(this).width($originals.eq(index).width());
        });
        return $helper;
    },
};

$('table.sortable tbody').sortable({
    helper: Sortable.helper,
    cursor: 'move',
    opacity: 0.5,
    delay: 100,
    handle: 'td.sort-handle',
    update: function(){
        let url = $(this).attr('data-url');
        let rows = $(this).find('tr[data-uuid]');
        let uuids = [];
        rows.each(function(i, elem){
            let uuid = $(elem).attr('data-uuid');
            uuids.push(uuid);
        });
        $.ajax({
            type: 'POST',
            url: url,
            data: {uuids: uuids}
        }).done(function(response){
            if (response.result === 'OK'){
                toastr.success('並び順を保存しました');
            } else if (response.result === 'ERROR'){
                toastr.error(response.data.error);
            }
        });
    }
});

$('div.sortable').sortable({
    helper: Sortable.helper,
    cursor: 'move',
    opacity: 0.5,
    delay: 100,
    handle: 'div.sort-handle',
    update: function(e, ui){
        let url = $(this).attr('data-url');
        let rows = $(this).find('div.item[data-uuid]');
        let uuids = [];
        rows.each(function(i, elem){
            let uuid = $(elem).attr('data-uuid');
            uuids.push(uuid);
        });
        $.ajax({
            type: 'POST',
            url: url,
            data: {uuids: uuids}
        }).done(function(response){
            if (response.result === 'OK'){
                toastr.success('並び順を保存しました');
                let callback = $(e.target).attr('data-on-sorted');
                if (callback){
                    window[callback]();
                }
            } else if (response.result === 'ERROR'){
                toastr.error(response.data.error);
            }
        });
    }
});
