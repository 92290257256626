export default class GlobalCommand {
    static init(){
        return;

        this.searchBox = document.getElementById('global-command');
        this.dropdown = new bootstrap.Dropdown(document.getElementById('global-command-container'));

        this.searchBox.addEventListener('keyup', e => {
            if (e.ctrlKey && (e.key === 'n' || e.key === 'p') || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
                GlobalCommand.moveCursor(e);
            } else if (e.key === 'Enter'){
                console.log('enter');
            } else if (e.key === 'Escape'){
                e.preventDefault();
                GlobalCommand.blur();
            } else {
            }
        });
        this.searchBox.addEventListener('input', e => {
            console.log('input: ' + this.searchBox.value);
            if (this.timeoutId){
                clearTimeout(this.timeoutId);
                this.timeoutId = undefined;
            }
            this.timeoutId = setTimeout(() => {
                GlobalCommand.doSearch();
            }, 1000);
        });
    }


    // /            this.dropdown.show();
    static blur(){
        console.log('GlobalCommand.blur()');
        this.searchBox.blur();
        this.dropdown.hide();
    }

    static doSearch(){
        console.log('do search');
        this.dropdown.show();
    }

    static moveCursor(e){
        let container = document.getElementById('global-command-result');
        // find last selected item
        let activeIndex = -1;
        let dropdownItems = container.querySelectorAll('.dropdown-item');
        let activeItem = [...dropdownItems].find(item => item.classList.contains('active'));
        if (activeItem) {
            activeIndex = parseInt(activeItem.getAttribute('data-index'));
        }
        // console.log('ActiveIndex = ' + activeIndex);
        // Change index
        if (activeIndex === -1) {
            activeIndex = 0;
        } else {
            if (e.key === 'n' || e.key === 'ArrowDown') {
                activeIndex = (activeIndex + 1) % dropdownItems.length;
            } else if (e.key === 'p' || e.key === 'ArrowUp') {
                activeIndex = ((activeIndex + dropdownItems.length) - 1) % dropdownItems.length;
            }
        }
        // console.log('New ActiveIndex = ' + activeIndex);
        // Clear .active
        let activeDropdownItems = container.querySelectorAll('.dropdown-item.active');
        [...activeDropdownItems].forEach(item => {
            item.classList.remove('active');
        });
        // Set .active
        let newActiveDropdownItem = container.querySelector('.dropdown-item[data-index="' + activeIndex + '"]');
        if (newActiveDropdownItem) {
            newActiveDropdownItem.classList.add('active');
        }
    }
}
