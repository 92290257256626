import TagSelector from 'fortee/js/module/tag-selector';
import axios from 'axios';
export default class TagEntity {
    static init(){
        const selectorDropdownElements = document.querySelectorAll('.tag-selector');
        selectorDropdownElements.forEach(element => {
            if (! element.classList.contains('tag-selector-initialized')){
                element.addEventListener('show.bs.dropdown', function() {
                    TagSelector.showDropDown(this);
                });
                element.addEventListener('hidden.bs.dropdown', function() {
                    TagSelector.hideDropDown(this);
                });
                element.classList.add('tag-selector-initialized');
            }
        });
    }

    static added(root, clicked){
        let target = root.getAttribute('data-target');
        let type = root.getAttribute('data-type');
        let tag = clicked.getAttribute('data-uuid');

        let url = document.getElementById('addTagEndpoint').getAttribute('action');

        axios
            .post(
                url,
                {
                    'target': target,
                    'type': type,
                    'tag': tag
                }
            )
            .then(response => {
                let data = response.data;
                if (data.result !== 'OK'){
                    toastr.error('タグの追加に失敗しました');
                }
                console.log(data);
                let tagsSelected = root.querySelector('.tags-selected');
                TagSelector.refreshSelectedTags(tagsSelected, data.data.tags);
            });

    }

    static removed(root, clicked){
        let target = root.getAttribute('data-target');
        let type = root.getAttribute('data-type');
        let tag = clicked.getAttribute('data-uuid');

        let url = document.getElementById('removeTagEndpoint').getAttribute('action');

        axios
            .post(
                url,
                {
                    'target': target,
                    'type': type,
                    'tag': tag
                }
            )
            .then(response => {
                let data = response.data;
                if (data.result !== 'OK'){
                    toastr.error('タグの削除に失敗しました');
                }
                console.log(data);
                let tagsSelected = root.querySelector('.tags-selected');
                TagSelector.refreshSelectedTags(tagsSelected, data.data.tags);
            });
    }
}
