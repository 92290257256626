import "../scss/fortee.scss";

import TagSelector from 'fortee/js/module/tag-selector';
import TokenSelector from "fortee/js/module/token-selector";
import CheckAll from 'fortee/js/module/check-all';
import FileUploader from 'fortee/js/module/file-uploader';
import ProposalFav from './module/proposal-fav';
import 'fortee/js/module/sortable';
import 'fortee/js/module/conference-text-editor';
import GlobalCommand from "fortee/js/module/global-command";
import Oembed from './module/oembed';
import Clipboard from "fortee/js/module/clipboard";
import 'fortee/js/module/textarea-auto-resizable';
import LargeFileUploader from "fortee/js/module/large-file-uploader";
import ForteePopover from "fortee/js/module/fortee-popover";

document.addEventListener('DOMContentLoaded', function() {
    ForteePopover.init(document);
    GlobalCommand.init();

    document.addEventListener('keyup', (e) => {
        const key = e.key;
        if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
            if (['global-command', 'search-box'].includes(document.activeElement.id) && key === 'Escape'){
                console.log('blur');
                document.activeElement.blur();
            }
            return;
        }

        if (key === 'm'){
            e.preventDefault();
            let body = document.querySelector('body');
            let current = body.getAttribute('data-kt-app-sidebar-minimize');
            if (current === 'on'){
                body.removeAttribute('data-kt-app-sidebar-minimize');
            } else {
                body.setAttribute('data-kt-app-sidebar-minimize', 'on');
            }
        }

        if ((! e.ctrlKey) && key === '/') {
            e.preventDefault();
            let searchBox = document.getElementById('global-command');
            searchBox.focus();
        }

        let searchBox = document.getElementById('search-box');
        if (searchBox && e.ctrlKey && key === '/'){
            e.preventDefault();
            searchBox.focus();
        }
    });

    document.addEventListener('keydown', function(event) {
        if ((event.metaKey || event.ctrlKey) && event.keyCode === 13) {
            let focusedElement = document.activeElement;
            let form = focusedElement.closest('form');
            if (form) {
                form.submit();
            }
        }
    });

    TagSelector.init();
    TokenSelector.init();
    CheckAll.init();
    FileUploader.init();
    LargeFileUploader.init();
    ProposalFav.init();
    Oembed.init();
    Clipboard.init();
});
