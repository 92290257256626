document.addEventListener('DOMContentLoaded', function() {
    let textAreas = document.querySelectorAll('textarea.auto-resizable');
    if (textAreas){
        [...textAreas].forEach((textarea) => {
            function autoResize() {
                textarea.style.height = "auto";
                textarea.style.height = textarea.scrollHeight + "px";
            }
            textarea.addEventListener("input", autoResize);
            autoResize();
        });
    }
});
