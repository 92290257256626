export default class ProposalFav {
    static init(){
        let favModal = document.getElementById('fav-modal');
        if (favModal){
            new bootstrap.Modal(favModal);
            $('div.proposal-fav').click(function(){
                if ($(this).attr('data-logged-in') !== 'true'){
                    let modalDom = document.getElementById('fav-modal');
                    console.log(modalDom);
                    let modal = bootstrap.Modal.getInstance(modalDom);
                    console.log(modal);
                    modal.show();
                    return;
                }

                let url = $('[data-fav-api]').attr('data-fav-api');
                let uuid = $(this).attr('data-uuid');
                let on = !$(this).hasClass('active');

                $.ajax({
                    type: 'POST',
                    url: url,
                    data: {
                        'uuid': uuid,
                        'on': on,
                    },
                    dataType: 'json'
                }).done(function(response){
                    if (response.result === 'OK'){
                        if (response.data.on){
                            $('div.fav[data-uuid="' + response.data.uuid + '"]').addClass('active');
                        } else {
                            $('div.fav[data-uuid="' + response.data.uuid + '"]').removeClass('active');
                        }
                    }
                });
            });
        }
    }
}
