import { closest } from '../common/closest';
export default class TokenSelector {

    static init(){
        let tokens = document.querySelectorAll('.token-selector .token');
        if (tokens){
            [...tokens].forEach((token) => {
                token.addEventListener('click', function(){
                    TokenSelector.insertText(this);
                });
            });
        }
    }

    static insertText(token){
        let textareaId = closest(token, '.token-selector').getAttribute('data-target');
        let textarea = document.getElementById(textareaId);
        if (! textarea){
            console.log('Something wrong.');
            return;
        }
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;
        let txt2insert = token.getAttribute('data-token');
        textarea.value = textarea.value.substring(0, startPos) + txt2insert + textarea.value.substring(endPos);

        textarea.selectionStart = startPos + txt2insert.length;
        textarea.selectionEnd = startPos + txt2insert.length;

        textarea.focus();
    }
}
