export default class Oembed {
    static init(){
        $('.oembed').each(function(index, element){
            Oembed.handle(element);
        });
    }

    static handle(element){
        let url = $(element).attr('data-url');
        let type = $(element).attr('data-type');
        let endpointUrl = $('#o-embed-endpoint').attr('action');
        let maxWidth = $('div.oembed[data-type="' + type + '"]').css('width');

        $.ajax({
            type: 'POST',
            url: endpointUrl,
            data: {
                'url': url,
                'type': type,
                'maxWidth': maxWidth
            },
            dataType: 'json'
        }).done(function(response){
            if (response.result === 'OK'){
                if (response.data.html){
                    $('div.oembed[data-type="' + response.data.type + '"]').html(response.data.html);
                } else {
                    $('div.oembed[data-type="' + response.data.type + '"]').parents().closest('.oembed-container').hide();
                }
            }
        });
    }
}
