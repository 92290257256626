export default class ForteePopover {
    static init(root){
        [].slice
            .call(root.querySelectorAll('[data-bs-toggle="popover"]'))
            .map((element) =>
                new bootstrap.Popover(element)
            );
        [].slice
            .call(root.querySelectorAll('[data-bs-toggle="popover-hover"]'))
            .map((element) =>
                new bootstrap.Popover(element, {
                    trigger: 'hover',
                    // placement: 'top',
                })
            );
        [].slice
            .call(root.querySelectorAll('[data-bs-toggle="tooltip"]'))
            .map((element) =>
                new bootstrap.Tooltip(element)
            );
    }
}
