
export default class Clipboard {
    static init() {
        document.querySelectorAll('.click2copy').forEach(element => {
            if (! element.classList.contains('click2copy-initialized')) {
                element.addEventListener('click', function () {
                    let text = element.getAttribute('data-text');
                    if (text) {
                        Clipboard.execCopy(text);
                    } else {
                        Clipboard.execCopy(element.innerText);
                    }
                    toastr.success('テキストをコピーしました');
                });
                element.classList.add('click2copy-initialized');
            }
        });
    }

    static execCopy(string) {
        let temp = document.createElement('div');

        temp.appendChild(document.createElement('pre')).textContent = string;

        let s = temp.style;
        s.position = 'fixed';
        s.left = '-100%';

        document.body.appendChild(temp);
        document.getSelection().selectAllChildren(temp);

        var result = document.execCommand('copy');

        document.body.removeChild(temp);

        return result;
    }
}
