import TagSelector from 'fortee/js/module/tag-selector';
import axios from 'axios';
import { closest } from '../common/closest';

export default class TagFilter {
    static init(){
        const filterDropdownElements = document.querySelectorAll('.tag-filter');
        filterDropdownElements.forEach(element => {
            if (! element.classList.contains('tag-filter-initialized')){
                element.addEventListener('show.bs.dropdown', function() {
                    TagSelector.showDropDown(this);
                });
                element.addEventListener('hidden.bs.dropdown', function() {
                    TagSelector.hideDropDown(this);
                });
                document.addEventListener('tag-selector-tag-loaded', (e) => {
                    TagFilter.restoreFilter();
                    let tagFilters = document.querySelectorAll('div.tag-filter');
                    [...tagFilters].forEach(root => {
                        TagFilter.doFilter(root);
                    });
                });
                element.classList.add('tag-filter-initialized');
            }
        });

        const radios = document.querySelectorAll('.filter-mode');
        radios.forEach(radio => {
            if (! radio.classList.contains('filter-mode-initialized')) {
                radio.addEventListener('change', function () {
                    if (this.checked) {
                        let selector = closest(this, '.tag-filter');
                        TagFilter.doFilter(selector);
                    }
                });
                radio.classList.add('filter-mode-initialized');
            }
        });
    }

    static added(root, clicked) {
        let selectedTag = TagFilter.findSelectedTag(root, clicked);
        let tagDiv = TagSelector.newTag(selectedTag);
        root.querySelector('.tags-selected').appendChild(tagDiv);
        TagFilter.doFilter(root);
    }

    static removed(root, clicked) {
        let selectedTag = TagFilter.findSelectedTag(root, clicked);
        let tagDivs = root.querySelectorAll('.tags-selected .tag');
        [...tagDivs].forEach(tagDiv => {
            if (tagDiv.getAttribute('data-uuid') === selectedTag.uuid){
                tagDiv.parentNode.removeChild(tagDiv);
            }
        });

        TagFilter.doFilter(root);
    }

    static doFilter(root){
        if (typeof beforeFilter === 'function'){
            beforeFilter();
        }

        // console.log('doFilter');
        // console.log(root);
        let target = root.getAttribute('data-target-class');
        let type = root.getAttribute('data-type');
        let mode = TagFilter.getSearchMode(root);
        let selectedTagUuids = TagFilter.selectedTagUuids(root);
        // console.log('target: ' + target);
        // console.log('type: ' + type);
        // console.log('mode: ' + mode);
        // console.log('selectedTagUuids:');
        // console.log(selectedTagUuids);

        let query = '.' + target + '[data-entity-type = "' + type + '"] .' + type;
        // console.log(query);
        let records = document.querySelectorAll(query);
        // console.log(records);
        // console.log('doFilter');
        [...records].forEach(entity => {
            // console.log(entity);
            let show = true;
            if (selectedTagUuids.length === 0){
                show = true;
            } else if (mode === 'or'){
                show = TagFilter.matchOr(entity, selectedTagUuids);
            } else if (mode === 'and'){
                show = TagFilter.matchAnd(entity, selectedTagUuids);
            } else if (mode === 'not'){
                show = TagFilter.matchNot(entity, selectedTagUuids);
            } else {
                console.log('Invalid mode: ' + mode);
            }
            if (show){
                TagFilter.show(entity);
            } else {
                TagFilter.hide(entity);
            }
        });

        const afterTagFilterEvent = new CustomEvent('after-tag-filter', {
            detail: {
                message: 'after tag filter event',
            }
        });
        document.dispatchEvent(afterTagFilterEvent);

        let hash = TagFilter.buildHash();
        window.location.hash = hash;
    }

    static matchOr(entity, selectedTagUuids){
        let found = false;
        selectedTagUuids.forEach(uuid => {
            if (entity.querySelector('.tags-selected .tag[data-uuid="' + uuid + '"]')){
                found = true;
            }
        });
        return found;
    }

    static matchAnd(entity, selectedTagUuids){
        let found = true;
        selectedTagUuids.forEach(uuid => {
            if (! entity.querySelector('.tags-selected .tag[data-uuid="' + uuid + '"]')){
                found = false;
            }
        });
        return found;
    }

    static matchNot(entity, selectedTagUuids){
        let found = true;
        selectedTagUuids.forEach(uuid => {
            if (entity.querySelector('.tags-selected .tag[data-uuid="' + uuid + '"]')){
                found = false;
            }
        });
        return found;
    }

    static buildHash(){
        let filters = document.querySelectorAll('.tag-filter');
        let params = new URLSearchParams();

        filters.forEach(root => {
            let target = root.getAttribute('data-target-class');
            let type = root.getAttribute('data-type');
            let mode = TagFilter.getSearchMode(root);
            let selectedTagUuids = TagFilter.selectedTagUuids(root);

            params.append('m-' + target + '-' + type, mode);
            params.append('tags-' + target + '-' + type, selectedTagUuids.join(','));
        });

        let query = (new URLSearchParams(params)).toString();
        return query;
    }

    static parseHash(hash){
        const result = {};
        const pairs = hash.slice(1).split('&');
        pairs.forEach(pair => {
            const [key, value] = pair.split('=');
            result[decodeURIComponent(key)] = decodeURIComponent(value);
        });
        return result;
    }

    static restoreFilter(){
        let hash = window.location.hash;
        let params = TagFilter.parseHash(hash);

        let tagsSelectedArray = document.querySelectorAll('.tag-filter .tags-selected');
        [...tagsSelectedArray].forEach(tagsSelected => {
            tagsSelected.innerHTML = '';
        });

        Object.keys(params).forEach(key => {
            const [name, target, type] = key.split('-');

            if (name !== 'm' && name !== 'tags'){
                return;
            }

            let root = document.querySelector('div.tag-filter[data-type="' + type + '"][data-target-class="' + target + '"]');
            if (name === 'm'){
                let id = 'filter-mode-' + type + '-' + target + '-' + params[key];
                let radio = root.querySelector('input[type="radio"][id="' + id + '"]');
                radio.checked = true;
            }
            if (name === 'tags'){
                let uuids = params[key].split(',');
                uuids.forEach(uuid => {
                    if (! uuid){
                        return;
                    }
                    if (! TagSelector.tags[type]){
                        return;
                    }
                    let selectedTag = TagSelector.tags[type].find(tag => tag.uuid === uuid);
                    let tagDiv = TagSelector.newTag(selectedTag);
                    root.querySelector('.tags-selected').appendChild(tagDiv);
                });
            }
        });
    }

    static show(element){
        if (element.classList.contains('d-none')) {
            element.classList.remove('d-none');
        }
    }
    static hide(element){
        if (! element.classList.contains('d-none')){
            element.classList.add('d-none');
        }
    }

    static selectedTagUuids(root){
        let tags = root.querySelectorAll('.tags-selected .tag');
        let uuids = [];
        [...tags].forEach(tag => {
            uuids.push(tag.getAttribute('data-uuid'));
        });
        return uuids;
    }

    static findSelectedTag(root, clicked){
        let type = root.getAttribute('data-type');
        let uuid = clicked.getAttribute('data-uuid');
        let selectedTag = TagSelector.tags[type].find(tag => tag.uuid === uuid);
        if (! selectedTag){
            console.log('Selected tag not found.');
        }
        return selectedTag;
    }

    static getSearchMode(root){
        const radio = root.querySelector('.filter-mode:checked');
        const mode = radio.getAttribute('value');
        return mode;
    }
}
