export default class CheckAll{
    static init(){
        let selectAllCheckboxes = document.querySelectorAll('.check-all');
        [...selectAllCheckboxes].forEach(selectAllCheckbox => {
            selectAllCheckbox.addEventListener('change', function(){
                // console.log(this);
                let targetClass = this.getAttribute('data-target-class');
                if (! targetClass){
                    return;
                }
                let checkboxes = document.getElementsByClassName(targetClass);
                // console.log(checkboxes);
                if (checkboxes.length === 0){
                    return;

                }
                let checked = this.checked;
                [...checkboxes].forEach(checkbox => {
                    checkbox.checked = checked;
                });
            });
        });
    }
}
